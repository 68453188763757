<template>
  <div>
    <Header ref="header"/>

    <div class="content article-content">
      <div style="height: 20px"></div>
      <div class="image-container">
        <img src="http://static.yabyy.com/rule/pc_grid.png">
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'

export default {
  name: "index",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      list:[]
    }
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  created() {
  },
  methods:{

  }
}
</script>
<style scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center; /* 水平居中 */
}
.image-container {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 100%; /* 确保容器高度占据父容器高度 */
  width: 100%;
}
img {
  max-width: 100%;
  height: auto; /* 保持图片的宽高比 */
}
</style>